// *** BUMP THIS VERSION ONLY WHEN UPDATING STARTER TEMPLATE ***
// BH Starter Template Version: 6.0.4

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'WOW',

  // App Description (full name)
  appDescription: 'Wellness on Wheels',

  // App Version Number
  appVersion: '6.2.9',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/wow',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.caseshare.apps.baystatehealth.org',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' and 'dark' will force their respective themes and not allow user to change.
  theme: 'user',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  // Language Codes
  // Used to recall event property language text
  languageCodes: {
    eventName: 'eventName',
    description: 'description',
    details: 'details',
    headline: 'headline',
    visitInfo: 'visitInformation'
  }
};
