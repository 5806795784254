import { LoadingConfig } from './../../../models/_core/loading-config';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { AppError } from '../../../models/_core/app-error';
import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ToastController } from '@ionic/angular';
import { AlertOptions } from '@ionic/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * ID: bh-notifications-service
 * Name: BH Notifications Service
 * Description: Service used for managing notifications, alert messaging, and errors.
 * Version: 4
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented dev warning and alert colors
 * 2021-10-11 - MW - v3: Suppressed 401 pop-up alerts
 * 2022-06-08 - MW - v4: Added messaging to global loading spinner
 */
@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  env = environment;
  isLoading = false;
  loadingConfig: LoadingConfig = { isLoading: false, message: ''};
  isLoadingBehaviorSubject: BehaviorSubject<LoadingConfig> = new BehaviorSubject(this.loadingConfig);
  loadingElem: HTMLIonLoadingElement = null;
  errorList: AppError[] = [];
  dismissLoadingTimeout = null;
  isErrorShowing = false;

  constructor(
    private loadingController: LoadingController,
    private alertController: AlertController,
    private toastController: ToastController,
    private router: Router
  ) {
  }

  /***
   * Display loading spinner
   */
  async startLoading(message: string = '') {
    if (!this.isLoading) {
      this.isLoading = true;
      this.loadingConfig.isLoading = true;
      this.loadingConfig.message = message;
      this.isLoadingBehaviorSubject.next(this.loadingConfig);
      // if (this.dismissLoadingTimeout) {
      //   clearTimeout(this.dismissLoadingTimeout);
      // }

      // this.dismissLoadingTimeout = setTimeout(async () => {
      //   this.stopLoading();
      // }, (timeoutSeconds * 1000));

      // return await this.loadingElem.present();
    }

  }

  /***
   * Hide loading spinner
   */
  stopLoading() {
    if (this.isLoading) {
      if (this.dismissLoadingTimeout) {
        clearTimeout(this.dismissLoadingTimeout);
      }
      this.dismissLoadingTimeout = setTimeout(async () => {
        this.isLoading = false;
        this.loadingConfig.isLoading = false;
        this.loadingConfig.message = '';
        this.isLoadingBehaviorSubject.next(this.loadingConfig);
      }, 500);
    }
  }

  /**
   * Displays alert message
   *
   * @param header Header text of the alert message
   * @param message subHeader or body text of the alert message
   * @param dismissAfter Milliseconds to dismiss this alert after
   */
  async showAlert(header: string, message?: string, alertType?: 'information' | 'warning' | 'danger' | 'success', dismissAfter?: number) {
    const options: AlertOptions = {
      header,
      message,
      cssClass: 'wide-alert ' + alertType,
      buttons: [
        {
          text: 'Okay',
          cssClass: 'primary'
        }
      ]
    };

    const alert = await this.alertController.create(options);

    await alert.present();
    if (dismissAfter) {
      setTimeout(() => {
        alert.dismiss();
      }, dismissAfter);
    }
  }

  /**
   * Displays toast message at the top of screen
   *
   * @param msg Message to display in toast
   * @param dismissAfter Milliseconds to dismiss the toast
   */
  async showToast(msg: string, dismissAfter: number = 5000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: dismissAfter,
      position: 'top',
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          handler: () => {
          }
        }
      ]
    });

    return await toast.present();
  }

  showDevWarning() {
    this.showAlert(this.env.appDescription + ' DEV Version',
      'You\'re using the non-production DEVELOPMENT version of ' + this.env.appDescription + '.' +
      'This version is used for testing and development of new features and bug fixes. ' +
      '<br><br>Please use the PRODUCTION version for actual work (if available).', 'warning');

  }

  /**
   * Handles errors and user notifications.  Sends error contents to console, parses error for toast, displays error toast.
   *
   * @param errorData  Send error of any format for parsing (httpErrorResponse, string, etc.)
   * @param userFriendlyText (optional) Define user-friendly error text.
   * Leave out or empty to display error object's message; Send 'skip_toast' to suppress error toast.
   */
  async handleError(errorData: any, action: string, parameters: any = null,
    userFriendlyText: string = 'An unexpected application error occurred.') {
    let msg = '';
    let showError = true;

    // Console log error/response obj and remove outstanding loading modal
    console.log(errorData);
    this.stopLoading();

    // Parse error message
    if (typeof errorData === 'string') {
      msg = errorData;
    } else if (errorData.errors) {
      msg = `Status: ${errorData.statusText} - ${errorData.errors[0].messages[0]}`;
    } else if (errorData.error && errorData.error.errors) {
      msg = `Status: ${errorData.statusText} - ${errorData.error.errors[0].messages[0]}`;
    } else if (errorData.error && !errorData.error.errors) {
      msg = errorData.error.message;
    } else {
      msg = errorData.message;
    }

    // Add error to error list
    const err: AppError = {
      message: msg,
      page: this.router.url,
      timestamp: new Date().toISOString(),
      errorData
    };
    this.errorList.push(err);

    // Swap out error user-friendly text
    if (userFriendlyText && userFriendlyText.trim().length > 0) {
      msg = userFriendlyText.trim();
    }

    // Check for error exceptions
    if (msg) {
      switch (msg.trim().toLowerCase()) {
        case 'no_alert':
        case 'cordova_not_available':
        case 'plugin_not_installed':
        case 'key [_ss_un] not found.':
          showError = false;
      }
    }

    // Show error pop-up
    if (showError && userFriendlyText && !this.isErrorShowing && !this.is401Error(errorData)) {
      this.isErrorShowing = true;
      this.showError(msg);
    }
  }

  is401Error(error): boolean {
    if (error instanceof HttpErrorResponse) {
      const msg = error.message;
      if (error.status === 401) {
        return true;
      }
    }
    return false;
  }

  async showError(msg) {
    const genInfo = '<br><br>Please try again. If issue persists, please contact the I&T Service Desk: 413-794-3000.';
    const alert = await this.alertController.create({
      header: 'Application Error',
      message: msg + ' ' + genInfo,
      backdropDismiss: false,
      cssClass: 'wide-alert error',
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          cssClass: 'primary',
          handler: () => {
            this.isErrorShowing = false;
            // console.log('Dismiss clicked');
          }
        }
      ]
    });

    await alert.present();

  }

}
