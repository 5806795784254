import { Injectable } from '@angular/core';
import { BhAmplifyPushNotifications, SettingKeyValuePair } from '@baystatehealth/amplify-push-notifications';
import { AuthService } from '../_core/auth/auth.service';
import { Amplify, Analytics, Notifications } from 'aws-amplify';
import { ModalController, Platform } from '@ionic/angular';
import { NotificationsService } from '../_core/notifications/notifications.service';
import { PushNotifications } from '@capacitor/push-notifications';
import * as moment from 'moment';
import { BhAnalyticsService } from '../_core/bhanalytics/bhanalytics.service';
import { AppConfig } from 'src/app/models/app-config';
import { SetupPage } from 'src/app/pages/user-pages/setup/setup.page';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AmplifyPushService {
  env = environment;
  locPerm = '';
  pushPerm = '';
  pushDeviceId = '';
  pushUserId = '';
  // permStatus = '';
  appConfig: AppConfig;
  pushMessages: any[] = [];
  serviceReady = false;

  constructor(
    private authService: AuthService,
    private notifications: NotificationsService,
    private platform: Platform,
    private modalCtrl: ModalController,
    private http: HttpClient,

  ) { }

  async init(referrer: string): Promise<void> {
    try {
      // Set App Config
      this.appConfig = await this.getAppConfig().toPromise();
      this.appConfig.geoApiUrl = this.env.apiUrl + '/auth';
      this.setOSAppConfig();

      if (this.platform.is('capacitor')) {
        // Check Permissions
        const perms = await BhAmplifyPushNotifications.checkPermissions();
        this.locPerm = perms.location;
        this.pushPerm = perms.notifications;

        // Init listeners
        await this.addListeners();
      } else {
        this.configure(this.appConfig);
      }
      return Promise.resolve();
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  async setOSAppConfig(): Promise<void> {
    try {
      const authUser = this.authService.getAuthUser();
      const config = {
        pinpointAppId: this.appConfig.pinpointAppId,
        cognitoIdentityPoolId: this.appConfig.cognitoIdentityPoolId,
        geoApiUrl: this.appConfig.geoApiUrl,
        token: authUser.token,
        regCode: authUser.regCode
      };
      // console.log('setOSAppConfig: config: ', config);
      const result = await BhAmplifyPushNotifications.saveToOS(config);
      // console.log('saveToOS: result', result);
      return Promise.resolve();
    } catch (err) {
      console.error('Error', err);
      return Promise.reject(err);
    }
  }

  async configure(appConfig: AppConfig): Promise<void> {
    const authUser = this.authService.getAuthUser();
    // console.log('Amplify: Preparing to configure', authUser);
    const configuration = {
      // To get the AWS Credentials, you need to configure
      // the Auth module with your Cognito Federated Identity Pool
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Auth: {
        identityPoolId: appConfig.cognitoIdentityPoolId,
        region: appConfig.region || 'us-east-1'
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Analytics: {
        disabled: false,
        autoSessionRecord: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        AWSPinpoint: {
          appId: appConfig.pinpointAppId,
          region: appConfig.region || 'us-east-1',
          endpoint: {
            address: this.pushDeviceId,
            channelType: this.getChannelType(),
            optOut: 'NONE',
            userId: authUser.id,
            userAttributes: {
              locale: ['en-US']
            }
          },
          bufferSize: 1000,
          flushInterval: 5000, // 5s
          flushSize: 100,
          resendLimit: 5
        }
      }
    };

    Amplify.configure(configuration);
    // console.log('Amplify: Configured');
    return Promise.resolve();
  }

  getChannelType(): string {
    if (this.platform.is('capacitor')) {
      return this.platform.is('ios') ? 'APNS' : 'GCM';
    } else {
      return '';
    }
  }

  async requestLocPermissions(): Promise<void> {
    await BhAmplifyPushNotifications.requestLocationPermissions();
    const perms = await BhAmplifyPushNotifications.checkPermissions();
    this.locPerm = perms.location;
    return Promise.resolve();
  }

  requestPushPermissions(): Promise<void> {
    return this.registerNotifications();
  }

  addListeners = async () => {
    // console.log('Amplify: Adding listeners');
    await PushNotifications.addListener('registration', async token => {
      // console.log('Registration token: ', token.value);
      this.pushDeviceId = token.value;
      const res = await BhAmplifyPushNotifications.saveSetting({ key: 'pushDeviceId', value: this.pushDeviceId});
      const authUser = this.authService.getAuthUser();
      this.pushUserId = authUser.userId;
      await this.configure(this.appConfig);
      // await this.identifyUser();
    });

    await PushNotifications.addListener('registrationError', err => {
      console.error('Registration error: ', err.error);
      this.notifications.showAlert('Error', err.error, 'danger');
    });

    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('Push notification received: ', notification);
      // this.notifications.showAlert(notification.title, notification.body, 'success');
      const logEntry = {
        id: notification.id,
        title: notification.title,
        subtitle: notification.subtitle,
        body: notification.body,
        data: notification.data,
        validMessage: notification.title || notification.body ? true : false,
        time: moment().format('M/D/YYYY HH:mm:ss')
      };
      const log = Object.assign([], this.pushMessages);
      log.unshift(logEntry);
      this.pushMessages = log.slice(0, 10);
    });

    await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      console.log('Push notification action performed', notification.actionId, notification.inputValue);
      // this.notifications.showAlert('Notification Action', notification.actionId, 'information');
    });

    return Promise.resolve();
  };

  registerNotifications = async () => {
    let permStatus = await PushNotifications.checkPermissions();
    this.pushPerm = permStatus.receive;

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
      permStatus = await PushNotifications.checkPermissions();
      this.pushPerm = permStatus.receive;
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    await PushNotifications.register();
  };

  getDeliveredNotifications = async () => {
    const notificationList = await PushNotifications.getDeliveredNotifications();
    console.log('delivered notifications', notificationList);
  };

  async identifyUser(): Promise<void> {
    const authUser = this.authService.getAuthUser();

    const endpoint = {
      address: this.pushDeviceId,
      optOut: 'NONE',
      userId: authUser.id,
      userAttributes: {
        locale: ['en-US']
      }
    };
    await Analytics.updateEndpoint(endpoint);
    await this.setOSAppConfig();
    return Promise.resolve();
  }


  async setup(): Promise<void> {
    try {
      if (!this.serviceReady) {
        // Check Permissions
        const perms = await BhAmplifyPushNotifications.checkPermissions();

        // Check for Privacy Agreement
        const authUser = await this.authService.validateUser().toPromise();
        const legalAcceptedAt = authUser.legalAcceptedAt;
        // console.log('setup: legalAccepted', legalAcceptedAt, authUser);

        if (
          (
            // Notification Perms not granted and are applicable
            perms.notifications !== 'granted' &&
            perms.notifications !== 'n/a'
          ) ||
          (
            // Location Perms not granted and are applicable
            perms.location !== 'granted' &&
            perms.location !== 'n/a'
          ) ||
          // Legal not accepted
          !legalAcceptedAt
        ) {
          const modal = await this.modalCtrl.create({
            component: SetupPage,
            cssClass: 'wide-modal',
            backdropDismiss: false,
            componentProps: {}
          });

          modal.onDidDismiss().then(data => {
            if (data && data.data) {
              const isReady = data.data.isReady;
              this.serviceReady = isReady;
            }
          });

          await modal.present();
        }
      } else {
        await this.requestPushPermissions();
      }
      return Promise.resolve();

    } catch (err) {
      this.notifications.handleError(err, 'amplifyPush.setup');
    }
  }

  getAppConfig(): Observable<AppConfig> {
    const url = `${this.env.apiUrl}/auth/config`;
    return this.http.get(url).pipe(
      map((data: any) => data)
    );
  }

  async getSetting(key: string): Promise<SettingKeyValuePair> {
    try {
      const data = { key };
      console.log('ampPush: getSetting', data);
      return BhAmplifyPushNotifications.getSetting(data);
    } catch (err) {
      console.error('Error: ', err);
    }
  }

  async saveSetting(key: string, value: string): Promise<SettingKeyValuePair> {
    try {
      const data = { key, value };
      return BhAmplifyPushNotifications.saveSetting(data);
    } catch (err) {
      console.error('Error: ', err);
    }
  }


}
