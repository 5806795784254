import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { DevicePermissions } from 'src/app/models/device-permissions';
import { SetupPanel } from 'src/app/models/setup-panel';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { BhAmplifyPushNotifications } from '@baystatehealth/amplify-push-notifications';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings';
import { AmplifyPushService } from 'src/app/services/amplify-push/amplify-push.service';
import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { TranslatorService } from 'src/app/services/translator/translator.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.page.html',
  styleUrls: ['./setup.page.scss'],
})
export class SetupPage implements OnInit {
  @ViewChild('scrollContainer', { static: false }) scrollContainer: ElementRef;
  @ViewChild('ionContentInner', { static: false }) ionContentInner: ElementRef;
  setupHeading: 'Setup' | 'Privacy' = 'Setup';
  viewState: 'setup-workflow' | 'privacy-declined' = 'setup-workflow';
  simulateMobile = false; // Testing only flag
  activeIndex = 0;
  devicePerms: DevicePermissions;
  testValue: string;
  alwaysOnInstructions = '';
  initSetupPanels: SetupPanel[] = [
    {
      id: 'WELCOME',
      title: 'Welcome to Baystate Health\'s Wellness on Wheels (WOW) Bus App',
      detail: 'Use the WOW Bus App to find and attend health services and events in your community.',
      help: '',
      required: true,
      valid: 'pristine',
      invalidMessage: '',
      ionIcon: '',
      primaryButtonLabel: 'Continue',
      primaryButtonAction: 'start-setup',
      showPrimaryButton: true,
      secondaryButtonLabel: '',
      secondaryButtonAction: '',
      showSecondaryButton: false,
      skipButtonLabel: '',
      skipButtonAction: '',
      showSkipButton: false,
      platform: 'all'
    },
    {
      id: 'PRIVACY',
      title: 'Read and accept our terms and privacy statements.',
      detail: '',
      help: '',
      required: true,
      valid: 'pristine',
      ionIcon: 'lock-closed-outline',
      invalidMessage: '',
      primaryButtonLabel: 'I Accept',
      primaryButtonAction: 'accept-privacy',
      showPrimaryButton: true,
      secondaryButtonLabel: 'I Decline',
      secondaryButtonAction: 'decline-privacy',
      showSecondaryButton: true,
      skipButtonLabel: '',
      skipButtonAction: '',
      showSkipButton: false,
      platform: 'all'
    },
    {
      id: 'NOTIFICATIONS',
      title: 'Push Notifications',
      // eslint-disable-next-line max-len
      detail: 'Allow push notifications on your device so we can notify you when the WOW Bus is nearby for new events and services.',
      help: '',
      required: true,
      valid: 'pristine',
      ionIcon: 'notifications-outline',
      invalidMessage: 'You chose not to allow push notifications. Open Settings and allow.',
      validMessage: 'You enabled notifications. Skip to the next step.',
      primaryButtonLabel: 'Allow',
      primaryButtonAction: 'enable-notifications',
      showPrimaryButton: true,
      secondaryButtonLabel: 'Open Settings',
      secondaryButtonAction: 'open-settings-notifications',
      showSecondaryButton: true,
      skipButtonLabel: 'Deny',
      skipButtonAction: 'skip-notifications',
      showSkipButton: true,
      platform: 'mobile'
    },
    {
      id: 'LOCATION',
      title: 'Location Services',
      detail: 'To be notified of nearby WOW Bus healthcare events, allow WOW Bus App to use your location. ' +
        'WOW Bus App collects location data to enable notifications of nearby healthcare events even when the app is closed or not in use.',
      help: '',
      required: true,
      valid: 'pristine',
      invalidMessage: 'You chose not to allow location services. Open Settings and allow.',
      validMessage: 'You allowed location access for this app. Skip to the next step.',
      ionIcon: 'navigate-outline',
      primaryButtonLabel: 'Allow',
      primaryButtonAction: 'enable-location',
      showPrimaryButton: true,
      secondaryButtonLabel: 'Open Settings',
      secondaryButtonAction: 'open-settings-location',
      showSecondaryButton: true,
      skipButtonLabel: 'Deny',
      skipButtonAction: 'skip-location',
      showSkipButton: true,
      platform: 'mobile'
    },
    {
      id: 'ALWAYS-ON-LOCATION',
      title: 'Always On Location Access',
      detail: 'To notify you of nearby WOW Bus healthcare events even when the app is closed or not in use, ' +
        'allow WOW Bus App to use your location all of the time. ' +
        'WOW Bus App collects location data to enable notifications of nearby healthcare events even when the app is closed or not in use.',
      help: this.setAlwaysOnInstructions(),
      required: true,
      valid: 'pristine',
      invalidMessage: '',
      validMessage: '',
      ionIcon: 'pin',
      primaryButtonLabel: 'Continue',
      primaryButtonAction: 'skip-location-silent',
      showPrimaryButton: true,
      secondaryButtonLabel: '',
      secondaryButtonAction: '',
      showSecondaryButton: false,
      skipButtonLabel: 'Skip',
      skipButtonAction: 'skip-location',
      showSkipButton: false,
      platform: 'mobile'
    },
    {
      id: 'COMPLETE',
      title: 'Setup is complete!',
      detail: 'You\'re all set. Thank you for participating in this app\'s pilot program.',
      help: '',
      required: true,
      valid: 'pristine',
      invalidMessage: '',
      ionIcon: 'checkmark-circle-outline',
      primaryButtonLabel: 'Go to Home',
      primaryButtonAction: 'go-home',
      showPrimaryButton: true,
      secondaryButtonLabel: '',
      secondaryButtonAction: '',
      showSecondaryButton: false,
      skipButtonLabel: '',
      skipButtonAction: '',
      showSkipButton: false,
      platform: 'all'
    },
  ];

  setupPanels: SetupPanel[] = [];

  constructor(
    private modalCtrl: ModalController,
    private authService: AuthService,
    private platform: Platform,
    private alertCtrl: AlertController,
    private amplifyPush: AmplifyPushService,
    private notifications: NotificationsService,
  ) { }

  ngOnInit() {
    this.loadPanels();
    this.setAlwaysOnInstructions();
  }

  async loadPanels() {
    // Check privacy agreement
    const legalAcceptedAt = await this.checkPrivacyAgreement();

    // Set active panels
    if (this.isMobile()) {
      // Include all panels
      this.setupPanels = Object.assign([], this.initSetupPanels);
      // Check Device Permissions
      await this.checkDevicePermissions();
      // Removed unrequired panels
      this.setupPanels = this.setupPanels.filter(p => p.required);
    } else {
      if (!legalAcceptedAt) {
        // Include only non-mobile panels for web
        this.setupPanels = this.initSetupPanels.filter(p => p.platform !== 'mobile');
        // Set heading
        this.setupHeading = 'Privacy';
      } else {
        this.completeSetup();
      }
    }
  }

  setAlwaysOnInstructions() {
    let alwaysOnInstructions = '';
    if (this.platform.is('android')) {
      alwaysOnInstructions = '<ol>' +
        '<li>Tap the <strong>Open Settings</strong> button below</li>' +
        '<li>Tap <strong>Permissions</strong></li>' +
        '<li>Tap <strong>Location</strong></li>' +
        '<li>Tap <strong>Allow all the time</strong></li>' +
        '</ol>';
    } else if (this.platform.is('ios')) {
      alwaysOnInstructions = '<ol>' +
        '<li>Tap the <strong>Open Settings</strong> button below</li>' +
        '<li>Tap <strong>Location</strong></li>' +
        '<li>Tap <strong>Always</strong></li>' +
        '</ol>';
    }

    return alwaysOnInstructions;
  }

  async checkPrivacyAgreement(): Promise<boolean> {
    try {
      // Check for Privacy Agreement
      const authUser = await this.authService.validateUser().toPromise();
      const legalAcceptedAt = authUser.legalAcceptedAt;

      if (legalAcceptedAt) {
        // Legal Accepted
        for (const panel of this.setupPanels) {
          switch (panel.id) {
            case 'PRIVACY':
              panel.required = false;
              break;
          }
        }
        return Promise.resolve(true);
      } else {
        return Promise.resolve(false);
      }
    } catch (err) {
      this.notifications.handleError(err, 'checkPrivacyAgreement');
    }
  }

  async checkDevicePermissions(): Promise<void> {
    if (this.isMobile()) {
      this.devicePerms = await BhAmplifyPushNotifications.checkPermissions();
      console.log('checkDevicePermissions', this.devicePerms);
      for (const panel of this.setupPanels) {
        switch (panel.id) {
          case 'LOCATION':
            this.setLocationPanel(panel);
            break;

          case 'NOTIFICATIONS':
            this.setNotificationsPanel(panel);
            break;
        }
      }
    }
    return Promise.resolve();
  }

  presentPanel(panelIndex = -1) {
    // Set index
    const i = panelIndex > -1 ? panelIndex : (this.activeIndex + 1);
    this.activeIndex = i;
    // console.log('presentPanel', panelIndex, this.activeIndex);
  }

  setNotificationsPanel(panel: SetupPanel) {
    console.log('setNotificationsPanel', this.devicePerms);
    switch (this.devicePerms.notifications) {
      case 'prompt':
      case 'not-determined':
        panel.valid = 'pristine';
        panel.showSecondaryButton = false;
        break;

      case 'granted':
        panel.valid = 'valid';
        panel.showPrimaryButton = false;
        panel.showSecondaryButton = false;
        panel.skipButtonLabel = 'Continue';
        panel.required = false;
        break;

      default:
        this.testValue = 'notifications perm: ' + this.devicePerms.location;
        panel.valid = 'invalid';
        panel.showPrimaryButton = true;
        panel.primaryButtonLabel = 'Open Settings';
        panel.primaryButtonAction = 'open-settings-notifications';
        panel.showSecondaryButton = false;
        break;
    }
  }

  setLocationPanel(panel: SetupPanel) {
    switch (this.devicePerms.location) {
      case 'prompt':
        panel.valid = 'pristine';
        panel.showSecondaryButton = false;
        break;

      case 'granted':
        panel.valid = 'valid';
        panel.showPrimaryButton = false;
        panel.showSecondaryButton = false;
        panel.skipButtonLabel = 'Continue';
        panel.required = false;
        break;

      default:
        this.testValue = 'location perm: ' + this.devicePerms.location;
        panel.valid = 'invalid';
        panel.showPrimaryButton = true;
        panel.primaryButtonLabel = 'Open Settings';
        panel.primaryButtonAction = 'open-settings-location';
        panel.showSecondaryButton = false;
        break;
    }
  }

  async invokeAction(action) {
    switch (action) {
      case 'start-setup':
        this.startSetup();
        break;

      case 'accept-privacy':
        this.acceptPrivacy(true);
        break;

      case 'decline-privacy':
        this.acceptPrivacy(false);
        break;

      case 'enable-notifications':
        this.setPushNotifications(true);
        break;

      case 'enable-location':
        this.setLocationServices(true);
        break;

      case 'go-home':
        this.completeSetup();
        break;

      case 'open-settings-notifications':
        this.openSettings('notifications');
        break;

      case 'open-settings-location':
        this.openSettings('location');
        break;

      case 'skip-notifications':
        this.showSkipWarning('notifications');
        break;

      case 'skip-location':
        this.setupPanels = this.setupPanels.filter(p => p.id !== 'ALWAYS-ON-LOCATION');
        this.showSkipWarning('location');
        break;

      case 'skip-location-silent':
        this.presentPanel();
        break;
    }
  }

  async openSettings(scope: 'notifications' | 'location'): Promise<void> {
    // const settingsPage = scope === 'notifications' ? 'notification_id' : 'location';
    const settingsPage = 'application_details';
    await OpenNativeSettings.open(settingsPage);
    return Promise.resolve();
  }

  async showSkipWarning(scope: 'notifications' | 'location') {
    let valid = false;
    if (
      scope === 'notifications' && this.devicePerms.notifications === 'granted' ||
      scope === 'location' && this.devicePerms.location === 'granted'
    ) {
      valid = true;
    }

    if (valid) {
      this.presentPanel();
    } else {
      const alert = await this.alertCtrl.create({
        header: 'Warning',
        message: 'By opting out of enabling this feature will limit your experience ' +
          ' and remove the ability to receive notifications on nearby healthcare events. ' +
          ' We strongly recommend enabling this feature now.',
        buttons: [
          {
            text: 'Skip',
            handler: () => {
              switch (scope) {
                case 'notifications':
                  this.presentPanel();
                  break;

                case 'location':
                  this.presentPanel();
                  break;
              }
            }
          },
          {
            text: 'Go Back',
            cssClass: 'primary'
          },
        ]
      });

      await alert.present();

    }
  }

  startSetup() {
    this.presentPanel(1);
  }

  async acceptPrivacy(isAccepted: boolean) {
    try {
      console.log('acceptPrivacy: setupPanels', this.setupPanels);
      // Handle next step
      if (isAccepted) {
        // Save privacy agreement response
        await this.savePrivacyAgreement(isAccepted);
        // User accepted privacy
        this.presentPanel();
      } else {
        // User declined privacy
        this.viewState = 'privacy-declined';
      }
    } catch (err) {
      this.notifications.handleError(err, 'acceptPrivacy');
    }
  }

  async savePrivacyAgreement(isAccepted: boolean): Promise<void> {
    try {
      const res = await this.authService.updatePrivacyAgreement(true).toPromise();
      if (res) {
        const authUser = this.authService.getAuthUser();
        authUser.legalAcceptedAt = res.legalAcceptedAt;
        this.authService.setAuthUser(authUser);
      }
      return Promise.resolve();
    } catch (err) {
      throw err;
    }
  }

  async setPushNotifications(enable: boolean) {
    if (enable) {
      // Prompt for push notifications
      try {
        await this.amplifyPush.requestPushPermissions();
        this.presentPanel();
      } catch (err) {
        console.error(err);
      }
    }
  }

  async setLocationServices(enable: boolean) {
    if (enable) {
      // Prompt for location services
      try {
        await this.amplifyPush.requestLocPermissions();
        this.presentPanel();
      } catch (err) {
        console.error(err);
      }
    }
  }

  completeSetup() {
    this.modalCtrl.dismiss({ isReady: true });
  }

  openWorkflow() {
    this.viewState = 'setup-workflow';
  }

  signOut() {
    this.modalCtrl.dismiss({ isReady: false });
    this.authService.logout(false, true);
  }

  isMobile(): boolean {
    return this.platform.is('mobile') || this.simulateMobile;
  }

}
