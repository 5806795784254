import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UserMenuPopoverPageRoutingModule } from './user-menu-popover-routing.module';
import { UserMenuPopoverPage } from './user-menu-popover.page';
import { PipesModule } from 'src/app/pipes/pipes.module';

@NgModule({
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    UserMenuPopoverPageRoutingModule,
    PipesModule
  ],
  declarations: [UserMenuPopoverPage]
})
export class UserMenuPopoverPageModule { }
